import React from "react";
import { createChatBotMessage,createCustomMessage } from 'react-chatbot-kit';
import CustomMessage from './CustomMessage';
import LearningOptions from "../components/LearningOptions/LearningOptions";
import GeneralYesNoOptions from "../components/Options/GeneralYesNoOptions";
import GeneralNewOptions from "../components/Options/GeneralNewOptions";
import HookTest from "../components/ApiDataList/HookTest";
import ChatBotAvatar from "../domainComponents/ChatBotAvatar"

const config = {
    initialMessages: [

        createCustomMessage('Test', 'custom',{
            widget: "learningOptions",
            delay:5000,
            withAvatar:true
        }),/**/
    ],
    customStyles: {
        botMessageBox: {
            backgroundColor: '#2A6EB5',
        },
        chatButton: {
            backgroundColor: '#78A416',
        },
        arrowCustomStyles:{
            borderRightColor: 'transparent'
        }

    },

    customComponents: {
        // Replaces the default bot avatar
        botAvatar: (props) => <ChatBotAvatar {...props} />,

    },
    customMessages: {
        custom: (props) => <CustomMessage {...props} />,
    },

    widgets: [
        {
            widgetName: "learningOptions",
            widgetFunc: (props) => <LearningOptions {...props} />,

        },
        {
            widgetName: "generalNewOptions",
            widgetFunc: (props) => <GeneralNewOptions {...props} />,
            mapStateToProps: ["newoptions"],
        },
        {
            widgetName: "generalYesNoOptions",
            widgetFunc: (props) => <GeneralYesNoOptions {...props} />,
            mapStateToProps: ["options"],
        },
        {
            widgetName: "apiCall",
            widgetFunc: (props) => <HookTest {...props} />,
            mapStateToProps: [
                "userMessage"
            ],
        }

    ],
}

export default config;