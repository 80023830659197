import React, {useEffect, useState} from "react";

import Options from "../Options/Options";
import {default as axios} from "axios";

const GeneralNewOptions = (props) => {
//console.log(props);
   // const options = props.newoptions;
    const [options, setoptions] = useState([]);

    useEffect( () => {
        setoptions(props.newoptions);
    }, []);
    return (
        <div className="options">
            <div className="options-container">
                {options.map(option => {
                    if(option.handlerName){
                        return (
                            <div
                                className="option-item test"
                                onClick={(event) => props.actionProvider.dynamicWidgetWork(event, option.targetMessageId)}
                                key={option.id}
                            >
                                {option.custommessage}
                            </div>
                        );
                    }else if(option.url){
                        return (
                            <div
                                className="option-item"
                                key={option.id}
                            >
                                <a
                                    href={option.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="link-list-item-url"
                                >
                                    {option.custommessage}
                                </a>
                            </div>
                        );
                    }

                })}
            </div>
        </div>
    );
};

export default GeneralNewOptions;