import logo from './logo.svg';
import React, { useState } from 'react'
import './App.css';
import 'react-chatbot-kit/build/main.css';
import Chatbot from 'react-chatbot-kit';
import config from './bot/config.js';
import Messageparser from './bot/Messageparser.js';
import ActionProvider from './bot/ActionProvider.js';

/*var x =document.getElementsByClassName("react-chatbot-kit-chat-input-container");
x[0].style.display === "none";*/
function App() {

    const saveMessages = (messages, HTMLString) => {
        localStorage.setItem('chat_messages', JSON.stringify(messages));
    };

    const loadMessages = () => {
        const messages = JSON.parse(localStorage.getItem('chat_messages'));
        return messages;
    };

  return (
      <div className="App">
          <header className="App-header">
              <Chatbot
                  config={config}
                  headerText='Chatbot'
                  actionProvider={ActionProvider}
                  messageHistory={loadMessages()}
                  messageParser={Messageparser}
                  saveMessages={saveMessages}
                  environmentName = 'staging'
                  disableScrollToBottom

              />
          </header>
      </div>

  );
}

export default App;
