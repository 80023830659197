import React from "react";

import Options from "../Options/Options";

const GeneralYesNoOptions = (props) => {
    const options = props.options;

    return <Options options={options} title="Options" {...props} />;
};

export default GeneralYesNoOptions;