import React from "react";
import {createCustomMessage} from "react-chatbot-kit";
import CustomMessage from './CustomMessage';
import dataT from "../jsonFile/Chatbot-messagedata-v03.json";

class ActionProvider {
    constructor(createChatBotMessage, setStateFunc, createCustomMessage) {
        this.createChatBotMessage = createChatBotMessage;
        this.createCustomMessage = createCustomMessage;
        this.setState = setStateFunc;
        this.showInitialMsgBox();
    }
    showInitialMsgBox() {
        const y = document.getElementsByClassName("react-chatbot-kit-chat-input-container");
        if (y.length > 0) {
            y[0].classList.add("showMsgBox");
        }
    }

    greet() {
        const greetingMessage = this.createChatBotMessage("Hi, friend.")
        this.updateChatbotState(greetingMessage)
    }
    dynamicWidgetWork(event, targetMessageId ){
        var dataT = require('../jsonFile/Chatbot-messagedata-v03.json');



         var x =document.getElementsByClassName("react-chatbot-kit-chat-input-container");
        if(targetMessageId[0] == 'Overview' && targetMessageId[1] ==1){
            this.handleBackToStart();
        }else{
            for (var data of dataT)
            {

                if(data.ParentName == targetMessageId[0] && data.messageId == targetMessageId[1]){
                    if(data.displayMessage && data.options){
                        var messagees =[];

                        for (var i=0;i<data.displayMessage.length-1;i++){
                            var msg =data.displayMessage[i];

                            if(msg.messageType=='text'){
                                const message = this.createChatBotMessage(
                                    <div dangerouslySetInnerHTML={{ __html: (msg.contents) }} />

                                );
                                this.updateChatbotState(message, event);
                            }if(msg.messageType=='image'){

                                const messageImg = this.createChatBotMessage(
                                    <a href={require(`../images/${msg.url}`)} target={"_blank"}><img src={require(`../images/${msg.url}`)} /></a>
                                    ,

                                );
                                this.updateChatbotState(messageImg, event);
                            }
                        }
                        let lastMessage = data.displayMessage[data.displayMessage.length - 1];

                        var msgContent = '';
                        if(lastMessage.messageType=='text'){
                            msgContent =  <div dangerouslySetInnerHTML={{ __html: (lastMessage.contents) }} />;
                        }if(lastMessage.messageType=='image'){
                            msgContent =  <a href={require(`../images/${lastMessage.url}`)} target={"_blank"}><img src={require(`../images/${lastMessage.url}`)} /></a>;

                        }

                        const optionMsg = [
                            {
                                message: msgContent,
                                widgetName: 'generalNewOptions',
                                newoptions:data.options
                            }
                        ]

                        setTimeout(() => this.handleDynamicMultipleMsg(optionMsg,event), 1000);

                    }
                    
                    else{
                        if (data.displayMessage) {

                            for (var msg of data.displayMessage){
                                if(msg.messageType=='text'){
                                    const message = this.createChatBotMessage(
                                        <div dangerouslySetInnerHTML={{ __html: (msg.contents) }} />

                                    );
                                    this.updateChatbotState(message, event);
                                }if(msg.messageType=='image'){
                                    const messageImg = this.createChatBotMessage(
                                        <a href={require(`../images/${msg.url}`)} target={"_blank"}><img src={require(`../images/${msg.url}`)} /></a>,

                                    );
                                    this.updateChatbotState(messageImg, event);
                                }

                            }
                        }
                        if(data.options){

                            const optionMsg = [
                                {
                                    message: "",
                                    widgetName: 'generalNewOptions',
                                    newoptions:data.options
                                }
                            ]

                            setTimeout(() => this.handleDynamicMultipleMsg(optionMsg,event), 1000);


                        }
                    }


                    if(data.allowUserInput){
                        x[0].className += " showMsgBox";
                    }if(data.allowUserInput==false){
                        x[0].classList.remove("showMsgBox");

                    }/**/
                    if(data.allowUserInput && data.onMessageShow){
                        this.saveSelectedOption('gonextposition',  data.onMessageShow);
                    }else{
                        if (data.allowUserInput){
                            const getbackto = {
                                "handlerName": "dynamicWidgetWork",
                                "targetMessageId": [
                                    data.ParentName,
                                    data.messageId
                                ]
                            }
                            this.saveSelectedOption('gonextposition',  getbackto);
                        }
                        if(data.onMessageShow){
                            this.storageDataclear();
                            this.dynamicWidgetWork(event, data.onMessageShow.targetMessageId);

                        }
                    }
                    if(data.optionToSendApi){
                        if (event !== null && event !== undefined && event!=='') {
                            this.saveSelectedOption('selectedOptions',  event.target.innerText);
                        }

                    }
                    if(data.showInHeader){
                        if (event !== null && event !== undefined && event!=='') {
                            this.handleHeaderMessage(event.target.innerText);
                        }

                    }






                    break;
                }


            }
        }



        //props.actionProvider.saveSelectedOption();

    }

    handleHealthCareNavigation = (event) => {
        this.storageDataclear();
        const message = this.createChatBotMessage(
            "Patient navigation refers to the support offered to persons with abnormal cancer screening or a new cancer diagnosis in accessing the cancer care system:",
            {
                widget: "HealthCareOptions"
            }
        );
        this.updateChatbotState(message, event);


    }
    saveSelectedOption = (name,messages) => {
        localStorage.setItem(name, JSON.stringify(messages));
    };

    storageDataclear = () => {
        /**
         * Removes all key/value pairs, if there are any.
         *
         * Dispatches a storage event on Window objects holding an equivalent Storage object.
         *  clear(): void;
         */

        localStorage.clear();
        /**
         * Removes the key/value pair with the given key, if a key/value pair with the given key exists.
         *
         * Dispatches a storage event on Window objects holding an equivalent Storage object.
         * removeItem(key: string): void;
         */


    };
    getSelectedOption = (name) => {
        const messages = JSON.parse(localStorage.getItem(name));
        return messages;
    };
    /*Back Buttons**/
    handleBackToTreatmentConsideration = () => {
        const message = this.createChatBotMessage(
            "OK, let me bring you back to the treatment options.",
            {
                widget: "treatmentConsiderations",
            }
        );
        this.updateChatbotState(message);
    }
    handleBackToStart = () => {
        const message = this.createChatBotMessage(
            "I will bring you back to the main menu.",
            {
                widget: "learningOptions",
            }
        );
        this.updateChatbotState(message);
    }
    handlegonextposition = () => {
        const getSelectedOption = this.getSelectedOption('gonextposition');
        this.dynamicWidgetWork('', getSelectedOption.targetMessageId);
        //this.storageDataclear();

    }
    handleApiCall = (userMessage) => {
        const genericMessages = ["Let me check, it will take a while, please bare with me.","Let me take a look at this; it could take a few moments, so thank you for your patience.","I'll have to check on that; it might take a bit, so please hang tight.","I'm on it! This might take a few seconds.","Hang tight, I'm gathering the information you need."];
        const random = Math.floor(Math.random() * genericMessages.length);

        const message = this.createChatBotMessage(
            genericMessages[random],
            {
                loading: true,
                terminateLoading: true,
                withAvatar: true,
                widget: "apiCall",
            }
        );
        this.setState((state) => ({
            ...state,
            userMessage: userMessage,
        }));
        this.updateChatbotState(message);
        var x =document.getElementsByClassName("react-chatbot-kit-chat-input-container");
        x[0].classList.remove("showMsgBox");
        setTimeout(() => this.handlegonextposition(), 2000);

    }
    /*Back Buttons**/
    handleDynamicMsg = (msg,options, selectedEvent=null) => {

        const message = this.createChatBotMessage(
            msg
        );
        const message1 = this.createChatBotMessage(
            'Would you like to know more?',
            {
                widget: "generalYesNoOptions",
                loading: true,
                terminateLoading: true
            }

        );
        this.setState((state) => ({
            ...state,

            options: options,
        }));
        this.updateChatbotState(message, selectedEvent);
        setTimeout(() => this.updateChatbotState(message1), 1000);

    };
    handleDynamicMultipleMsg = (msgs, selectedEvent=null) => {
        msgs.forEach((msg, index) => {
            var message = '';
            if(typeof msg.widgetName==='undefined'){
                message = this.createChatBotMessage(
                    msg.message
                );
            }else{
                message = this.createChatBotMessage(
                    msg.message,{widget: msg.widgetName}
                );

                this.setState((state) => ({

                    ...state,

                    newoptions: msg.newoptions,
                }));
            }

            setTimeout(() => this.updateChatbotState(message,selectedEvent), (index*500));
                var x =document.getElementsByClassName("react-chatbot-kit-chat-input-container");
                x[0].className += " showMsgBox";
        });

    };
    handleHeaderMessage = (msg) => {
        var chatHeader =document.getElementsByClassName("react-chatbot-kit-chat-header");
        chatHeader[0].innerHTML= "Chatbot ("+msg+")";

    };



    updateChatbotState(message, selectedEvent=null, newoptions =[]) {
        const selectedOptions= [];
        if (selectedEvent === null || selectedEvent === undefined || selectedEvent =='') {

        }else{

            var selectedOptionName = selectedEvent.target.innerText;
            selectedEvent.target.className += " selected";
            var parentEvent =selectedEvent.target.parentNode;
            var elements = parentEvent.childNodes;
            var elementsLength = elements.length;
            for (var i = 0; i <  elementsLength; i++) {
                if (elements[i].classList.contains('selected')) {

                }else{
                    elements[i].className += " hideDiv";
                }

            }
        }


// NOTE: This function is set in the constructor, and is passed in      // from the top level Chatbot component. The setState function here     // actually manipulates the top level state of the Chatbot, so it's     // important that we make sure that we preserve the previous state.

        this.setState(prevState => ({
            ...prevState, messages: [...prevState.messages, message]
        }))/**/
           //

    }
}

export default ActionProvider;
