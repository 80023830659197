import React from "react";

import "./Options.css";

// onClick={(event) => option.handlerName(event, 'test')}
const Options = props => {
  return (
    <div className="options">
      <div className="options-container">
        {props.options.map(option => {
          return (
            <div
              className="option-item"
              //onClick={option.handler}
              onClick={(event) => props.actionProvider.dynamicWidgetWork(event, option.targetMessageId)}
              key={option.id}
            >
              {option.custommessage}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Options;
