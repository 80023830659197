import React,{useEffect,useState}  from "react";


import "./ApiDataList.css";



const HookTest = (props) => {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const usermessage = props.userMessage;
    const getSelectedOption = props.actionProvider.getSelectedOption('selectedOptions');

    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    useEffect( () => {
        var endpoint = window.encodeURI(`https://aipl-staging.uhn.ca/ai/getresponse/`);
        
        if(props.environmentName=='production'){
             endpoint = window.encodeURI(`https://aipl.uhn.ca/ai/getresponse/`);
        }

        const fetchData = async () => {
            try {
                const response = await fetch(endpoint, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ message: usermessage, selectedOption: getSelectedOption })
                });

                const reader = response.body.getReader();
                let decodedText = '';
                const decoder = new TextDecoder();

                reader.read().then(function processText({ done, value }) {
                    if (done) {
                        setIsLoaded(true);
                        setItems({ response_text: decodedText });
                        return;
                    }

                    decodedText += decoder.decode(value);
                    setItems({ response_text: decodedText });
                    return reader.read().then(processText);
                });
            } catch (error) {
                setError(error);
                console.log(error);
            }
        };

        fetchData();
    }, []);

    const linkMarkup = (
        <span>
            <div dangerouslySetInnerHTML={{ __html: items.response_text }} />
        </span>
    );

    return (
        <div className="react-chatbot-kit-chat-bot-message" style={{ background: "#2A6EB5" }}>
            {linkMarkup}
        </div>
    );
}

export default HookTest;
