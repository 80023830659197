import React from "react";

import Options from "../Options/Options";
import dataT from "../../jsonFile/Chatbot-messagedata-v03.json";

const LearningOptions = (props) => {
    var dataT = require('../../jsonFile/Chatbot-messagedata-v03.json');
    for (var data of dataT)
    {
        if(data.ParentName =='Overview' && data.messageId ==1){
            var options = data.options;
            break;
        }
    }

    return <Options options={options} title="Options" {...props} />;
};

export default LearningOptions;
